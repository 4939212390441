import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import './App.css'; // All section styles in one CSS file
import 'bootstrap/dist/css/bootstrap.min.css';

// Components
import Nav from './components/Nav';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import BackToTop from './components/BackToTop';

// Pages
import ContactPage from './components/ContactPage';
import Donate from './components/Donate';
import AimsObjectives from './components/AimsObjectives';
import CSRActivities from './components/CSRActivities';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import VisionMission from './components/VisionMission';
import AnnouncementsEvents from './components/AnnouncementsEvents';
import Testimonials from './components/Testimonials';
import AwardsAccomplishments from './components/AwardsAccomplishments';
import TargetGroup from './components/TargetGroup';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import RefundPolicy from './components/RefundPolicy';
import MahilaVikas from './components/MahilaVikas';
import GalleryDetail from './components/GalleryDetail';
import DonationForm from './components/DonationForm';

// Layout component to wrap content with Nav and Footer
const Layout = ({ children }) => {
  const location = useLocation();
  const canonicalUrl = `https://aaradhyafoundation.org.in${location.pathname}`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Nav />
      <div className="main-content">{children}</div>
      <Footer />
    </>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <BackToTop />
        <div className="App">
          <Routes>
            {/* Homepage */}
            <Route
              path="/"
              element={
                <Layout>
                  <HeroSection />
                  <AboutUs />
                  <Testimonials />
                  <VisionMission />
                  <AnnouncementsEvents />
                  <AwardsAccomplishments />
                </Layout>
              }
            />

            {/* Other Pages */}
            <Route path="/MahilaVikas" element={<Layout><MahilaVikas /></Layout>} />
            <Route path="/gallery-detail" element={<Layout><GalleryDetail /></Layout>} />
            <Route path="/our-messages/aimsobjectives" element={<Layout><AimsObjectives /></Layout>} />
            <Route path="/our-messages/targetgroup" element={<Layout><TargetGroup /></Layout>} />
            <Route path="/csr-activity" element={<Layout><CSRActivities /></Layout>} />
            <Route path="/Contact" element={<Layout><ContactPage /></Layout>} />
            <Route path="/DONATE" element={<Layout><Donate /></Layout>} />
            <Route path="/PrivacyPolicy" element={<Layout><PrivacyPolicy /></Layout>} />
            <Route path="/termsandconditions" element={<Layout><TermsAndConditions /></Layout>} />
            <Route path="/refundpolicy" element={<Layout><RefundPolicy /></Layout>} />
            <Route path="/DonationForm" element={<Layout><DonationForm /></Layout>} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;