import React, { useState } from 'react';
import './ContactPage.css';
import Contactimg from '../assets/images/Website_Contact_Image.jpg';
import axios from 'axios';
import { FaMapPin, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    contact: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setsuccessMessage] = useState('');

  const validateForm = () => {
    const errors = {};
    const nameRegex = /^[a-zA-Z\s]{2,30}$/; // Allows only alphabets and spaces (2-30 characters)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
    const contactRegex = /^[6-9]\d{9}$/; // 10-digit Indian phone number starting with 6-9

    if (!nameRegex.test(formData.firstname)) {
      errors.firstname = 'First name must contain only alphabets and be 2-30 characters long.';
    }
    if (!nameRegex.test(formData.lastname)) {
      errors.lastname = 'Last name must contain only alphabets and be 2-30 characters long.';
    }
    if (!emailRegex.test(formData.email)) {
      errors.email = 'Invalid email format.';
    }
    if (!contactRegex.test(formData.contact)) {
      errors.contact = 'Invalid contact number. Must be a 10-digit Indian number starting with 6-9.';
    }
    if (formData.message.trim().length < 10) {
      errors.message = 'Message must be at least 10 characters long.';
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors({});

    try {
      const response = await axios.post('https://aaradhyafoundation.org.in/api/v1/contact/create', formData);

      if (response.status === 200) {
        setsuccessMessage('Your message has been successfully submitted!');
        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          contact: '',
          message: '',
        });
      } else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Failed to send the message. Please try again.');
    }
  };

  return (
    <div className="contact-page-container">
      <div className="form-section">
        <h2>Leave a Message</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>

          <div className="form-group">
            <input
              type="text"
              name="firstname"
              value={formData.firstname}
              placeholder="First Name"
              onChange={handleChange}
              required
            />
            {formErrors.firstname && <p className="error-message">{formErrors.firstname}</p>}
            <input
              type="text"
              name="lastname"
              value={formData.lastname}
              placeholder="Last Name"
              onChange={handleChange}
              required
            />
            {formErrors.lastname && <p className="error-message">{formErrors.lastname}</p>}
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              onChange={handleChange}
              required
            />
            {formErrors.email && <p className="error-message">{formErrors.email}</p>}
            <input
              type="tel"
              name="contact"
              value={formData.contact}
              placeholder="Contact"
              onChange={handleChange}
              required
            />
            {formErrors.contact && <p className="error-message">{formErrors.contact}</p>}
          </div>
          <div className="form-group full-width">
            <textarea
              name="message"
              value={formData.message}
              placeholder="Message"
              onChange={handleChange}
              required
            ></textarea>
            {formErrors.message && <p className="error-message">{formErrors.message}</p>}
          </div>
          
          <button type="submit" className="submit-button">
            Submit
          </button>

        </form>
      </div>
      <div className="card-section">
        <div className="card">
          <img src={Contactimg} alt="Office" />
          <div className="card-content">
            <h3 style={{color:'#295bb1'}}><FaMapPin style={{color: 'red', marginRight: '20px'}}/>AF Centre</h3>
            <p style={{paddingLeft: '37px'}}>
              LODHA SIGNET PALAVA 2, 2-549,<br /> 5th Floor, Dombivli (East) 421204,<br /> Dist. Thane,
              Maharashtra, INDIA.
            </p>
            <p><FaPhoneAlt style={{color: 'red', marginRight: '20px'}}/> +91 7498774211</p>
            <p><FaPhoneAlt style={{color: 'red', marginRight: '20px'}}/> +91 8779666070</p>
            <p><FaEnvelope style={{color: 'red', marginRight: '20px'}}/> info@aaradhyafoundation.org.in</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
