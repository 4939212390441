import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';  // All section styles in one CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// import Header from './components/header';
import Nav from './components/Nav';
// import Navbar from './components/Navbar';
import Footer from './components/Footer';

import ContactPage from './components/ContactPage';
// import Contact from './components/Contact';
import Donate from './components/Donate';

// Pages for Routing
// import PresidentMessages from './components/PresidentMessages';
// import PrincipalMessage from './components/PrincipalMessage';
// import SecretaryMessage from './components/SecretaryMessage';
import AimsObjectives from './components/AimsObjectives';
import CSRActivities from './components/CSRActivities';
// import ProjectBrochure from './components/ProjectBrochure';

// Main Page Components
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
// import Legacy from './components/Legacy';
import VisionMission from './components/VisionMission';
// import Courses from './components/Courses';
import AnnouncementsEvents from './components/AnnouncementsEvents';
import Testimonials from './components/Testimonials';
// import Advantage from './components/Advantage';
import AwardsAccomplishments from './components/AwardsAccomplishments';
// import FeeStructure from './components/FeeStructure';
import TargetGroup from './components/TargetGroup';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop Component
import BackToTop from './components/BackToTop';

import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import RefundPolicy from './components/RefundPolicy';

import MahilaVikas from './components/MahilaVikas';
// import Gallery from './components/Gallery';
import GalleryDetail from './components/GalleryDetail';

import DonationForm from './components/DonationForm';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// Layout component to wrap content with Header, Navbar, and Footer
const Layout = ({ children }) => (
  <>
    {/* <Header /> */}
    {/* <Navbar /> */}
    <Nav />
    <div className="main-content">
      {children}
    </div>
    <Footer />
  </>
);

const App = () => {
  return (
    
    <Router>
    <ScrollToTop />
    <BackToTop />
      <div className="App">
        <Routes>
          {/* Route for the homepage */}
          <Route path="/" element={
            <Layout>
              <HeroSection />
              <AboutUs />
              <Testimonials />
              {/* <Advantage />  */} {/* Our focus area */}
              <VisionMission />
              {/* <Legacy /> */}         {/* what the future look with us */}
              {/* <Courses /> */}
              <AnnouncementsEvents />
              {/* <Gallery /> */}
              <AwardsAccomplishments />
              {/* <FeeStructure />            */}
              
            </Layout>
          } />

          <Route path="/MahilaVikas" element={
            <Layout>
              <MahilaVikas />
            </Layout>
            } />

              <Route>
                {/* <Route path="/gallery" element={<Layout><Gallery /></Layout>} /> */}
                <Route path="/gallery-detail" element={<Layout><GalleryDetail /></Layout>} />
              </Route>

          {/* <Route path="/Gallery" element={
            <Layout>
              <Gallery />
            </Layout>
            } /> */}

          {/* Routes for specific pages, all wrapped with the Header, Navbar, and Footer */}
          {/* <Route path="/our-messages/president" element={
            <Layout>
              <PresidentMessages />
            </Layout>
          } />
          <Route path="/our-messages/principal" element={
            <Layout>
              <PrincipalMessage />
            </Layout>
          } />
          <Route path="/our-messages/secretary" element={
            <Layout>
              <SecretaryMessage />
            </Layout>
          } /> */}
          <Route path="/our-messages/aimsobjectives" element={
            <Layout>
              <AimsObjectives />
            </Layout>
          } />
          <Route path="/our-messages/targetgroup" element={
            <Layout>
              < TargetGroup/>
            </Layout>
          } />

          <Route path="/csr-activity" element={
            <Layout>
              <CSRActivities />
            </Layout>
          } />

          {/* <Route path="/Contact" element={
            <Layout>
              <Contact />
            </Layout>
          } /> */}
          <Route path="/Contact" element={
            <Layout>
              <ContactPage />
            </Layout>
          } />

          <Route path="/DONATE" element={
            <Layout>
              <Donate />
            </Layout>
          } />


        <Route path="/PrivacyPolicy" element={<Layout> <PrivacyPolicy /> </Layout>} />
        <Route path="/termsandconditions" element={<Layout><TermsAndConditions /></Layout>} />
        <Route path="/refundpolicy" element={<Layout><RefundPolicy /></Layout>} />
        {/* Add more routes as necessary */}
        <Route path="/DonationForm" element={<Layout><DonationForm /></Layout>}/>
        </Routes>
      </div>
      
    </Router>
  );
}


export default App;
