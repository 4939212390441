import React from 'react';
import aimsobjectivesImage from '../assets/images/ai-img5.avif'; // Update the path to your actual image
import aimsobjectivesImage1 from '../assets/images/ai-img7.jpg'; // Update the path to your actual image

const AimsObjectives = () => {
  return (
    <section className="aims-objectives-section">
      <h2>Aims & Objective of The Project</h2>
      <div className="aims-objectives-container">
        <div className="aims-objectives-image">
          <img src={aimsobjectivesImage} alt="Secretary" />
          <img src={aimsobjectivesImage1} alt="Secretary" />
        </div>
        <div className="aims-objectives-content">
          
            <p>
            To increase Adolescent Girls and Widows participation in alternate employment opportunities of development. The main objective of the Project is to provide Income Generation Training on Stitching and Embroidery to the Rural Women for their self-reliance and self-employment which support them Physically, Socially and economically and psychologically and to mould themselves as an active member of the community with all kinds of participatory functions. 
            </p><br />
            <h4>General Objectives</h4>
              <ol>
                <li>
                  To empower Rural Adolescent Girls and Widows and build self-confidence, self support and self-reliance.
                </li>
                <li>
                	To enable Adolescent Girls and Widows to development in mind, body and spirit.
                </li>
                <li>
                  To encourage and equip Adolescent Girls and Widows to become Literates and Leaders.
                </li>
                <li>
                  To equip Adolescent Girls and Widows to develop their skills in future.
                </li>
                <li>
                	The unemployed youth will become self-financier.
                </li>
              </ol>
              <br />
            <h4>Specific Objectives</h4>
              <ol>
                <li>
                  To form women self helping groups in 5 slums areas in Aurangabad.
                </li>
                <li>
                  To create employment opportunities to less privileged slum Adolescent Girls and Widows by introducing Stitching Training. 
                </li>
                <li>
                  To provide skill Stitching Training to Adolescent Girls and Widows to generate additional income to meet their basic needs.
                </li>
                <li>
                  To improve their socio economic and cultural standards.
                </li>
                <li>
                  To promote awareness and participation in family responsibilities.
                </li>
                <li>
                  To sustain small savings and networking with bans and other government bodies.
                </li>
                <li>
                  To provide skill development to improve self confidence among the Rural Adolescent Girls and Widows.
                </li>
                <li>
                  To establish a skill center exclusively for Rural Adolescent Girls and Widows.
                </li>
                <li>
                  To educate Adolescent Girls and Widows on the prevention of the causes of unemployment.
                </li>
              </ol>
        </div>
      </div>
    </section>
  );
};

export default AimsObjectives;
