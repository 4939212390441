import React from 'react';
import visionImage from '../assets/images/Icon_Vision.png';
import missionImage from '../assets/images/Icon_Mission.png';
import values from '../assets/images/Icon_Values.png';

const VisionMission = () => {
  return (
    <section className="vision-mission-section">
      <div className="vision-mission-container">
        {/* Vision Card */}
        <div className="vision-mission-card vision-card">
          <div className="card-image">
            <img src={visionImage} alt="Vision" />
          </div>
          <div className='card-content'>
            <h2>Vision</h2>
            <p>
            Our vision is the guiding purpose of the Foundation. It is a statement that describes the desired future state of the organization. The vision is to inspire and motivate the organization’s stakeholders including employees, volunteers, donors and beneficiaries. A vision statement is a written expression of your vision. It is a concise statement that captures the essence of your aspirations. A good vision statement should be clear, concise and inspiring. It should be easy for all stakeholders to understand & remember. A charity's vision is often inspired by the social or environmental issues that the organization seeks to address. Vision is a reflection of our values, beliefs and culture. It should align with the goals and objectives of the organization.
            </p>
          </div>
        </div>

        {/* Mission Card */}
        <div className="vision-mission-card mission-card">
          <div className="card-image">
            <img src={missionImage} alt="Mission" />
          </div>
          <div className='card-content'>
            <h2>Mission</h2>
            <p>
            Our mission is a clear and concise message that communicates the organization's purpose and why it exists.  It is to create a better world by providing services, resources and support to those in need. We work tirelessly to transform the lives of the vulnerable, marginalized or disadvantaged. A clear and concise mission statement is essential to guide an organization's strategy and decision-making processes. From poverty alleviation to improving education and healthcare, the charitable mission provides concrete solutions to pressing problems. By putting resources and energy into these efforts, we have the potential to create transformational change that will benefit not only individuals, but entire communities.
            </p>
          </div>
        </div>

        {/* What We Do */}
        <div className="vision-mission-card what-we-do-card">
          <div className="card-image">
            <img src={values} alt="What We Do" />
          </div>
          <div className='card-content'>
            <h2 style={{marginTop:'25px'}}>Values</h2>
            <p>
            We have values and principles that guide our mission and goals. These values are the foundation of our work and essential to our success. Some example of charitable values include Transparency, Dedication To Mission, Respect, Stewardship, Ethics, Education and Equality. We are committed to fostering a community that is dedicated to making a positive impact in the world through charitable giving. We believe that everyone has the ability to make a difference and we strive to create an environment that encourages and enables our members to do so to the best of their ability. At the heart of these values is a strong desire to help others and make a positive impact on the world.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisionMission;
