// src/components/Footer.js
import React, { useEffect, useState } from 'react';
// import logo from '../assets/images/logo removebg.png';
import BDHLogo from '../assets/images/Logo_png.png';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedin} from 'react-icons/fa';
// import VisitorCounter from './VisitorCounter';

const Footer = () => {
  const [visitorCount, setVisitorCount] = useState(0);
  useEffect(() => {
    const getVisitorCount = async () => {
      try {
        const response = await fetch("https://aaradhyafoundation.org.in/api/v1/visitor/count");
        const jsonData = await response.json();
        setVisitorCount(JSON.stringify(Math.ceil(jsonData.visitorCount)));
        console.log(JSON.stringify(jsonData));
      } catch (error) {
        console.error("Error fetching visitor count:", error);
      }
    };
    getVisitorCount();
  }, []);
  return (
    <>
    <footer className="footer" id="contact">
      <div className="footer-columns">
        {/* BDH Logo above Social Icons */}
        <div className="footer-BDHlogo-socials">
          <div className='footer-BDHlogo'>
          <a href="/">
            <p><img src={BDHLogo} alt="BDHLogo" className="footer-logo img-fluid" />
            </p>
          </a>
          {/* <p01>Aaradhya Foundation</p01> */}
          </div>

          {/* Social Icons */}
          <div className="footer-socials">
            <a href="https://www.facebook.com/people/Aaradhya-Foundation/61565444082517/" className="social-icon" target="_blank" style={{ color: "#4267B2" }}><FaFacebookF /></a>
            <a href="https://www.instagram.com/learn.believe.achieve/" className="social-icon" target="_blank" style={{ color: "#E4405F" }}><FaInstagram /></a>
            {/* <a href="https://google.com" className="social-icon"><FaGoogle /></a> */}
            <a href="https://www.youtube.com/@AaradhyaFoundation2024" className="social-icon" target="_blank" style={{ color: "#FF0000" }}><FaYoutube /></a>
            <a href="https://www.linkedin.com/company/aaradhya-foundation" className="social-icon" target="_blank" style={{ color: "#4267B2" }}><FaLinkedin /></a>
          </div>
        </div>

        {/* Column 2: School Address */}
        {/* <div className="footer-address">
          <p>
            <a href="/PrivacyPolicy"> Privacy Policy </a>
          </p>
          <p>
          <a href="/RefundPolicy"> Refund Policy </a>
          </p>
          <p>
          <a href="/TermsAndConditions"> Terms & Conditions </a>
          </p>
          
        </div> */}

        {/* Column 3: School Address */}
        <div className="footer-address">
          <p><strong>Head Office:</strong><br />
            At Post Shirodi Khurd, Taluka Phulambri, <br />
            Dist. Chhatrapati Sambhajinagar 431111.
          </p>
          <p><strong>Contact Us:</strong><br />
            +91 9504855555 <br />
            aaradhyafoundation30@gmail.com
          </p>
        </div>

         {/* Visitor Counter */}
         <div className="footer-visitor-counter">
         <i aria-hidden="true" class="fas fa-user-tie" style={{ fontSize: "24px", marginBottom: "10px" }}></i>
            <p>
              <strong>Website Visitors</strong>
            </p>
            <p className="visitor-count" data-toggle="counter-up">{visitorCount}</p>
          </div>

        {/* Column 4: Headquarter Address */}
        {/* <div className="footer-address">
          <p><strong>Headquarter Address:</strong><br />
            LODHA SIGNET PALAVA, Lodha Signet Palava 2,<br />
            2-549, 5TH Floor Dombivli East 421204.
          </p>
          <p><strong>Contact Us:</strong><br />
            +91 8779666070 <br />
            +91 7498774211 <br />
            info@aaradhyafoundation.org.in
          </p>
        </div> */}
        
      </div>

    </footer>

      <div className="footer-designed">
        <p>Copyright © Aaradhya Foundation 2024. All rights reserved </p>
        <p> 
          <a href="/TermsAndConditions"> Terms of use </a> | 
          <a href="/PrivacyPolicy"> Privacy Policy </a> |
          <a href="/RefundPolicy"> Refund Policy </a>
          </p>
      </div>

    </>
  );
};

export default Footer;
