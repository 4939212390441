import React from 'react';
import './MahilaVikas.css';
import Image1 from '../assets/images/ai-img8.jpg';
import  Image2 from '../assets/images/ai-img1.jpg';
import FocusImage1 from '../assets/images/Adolescent Girls img1.jpg';
import FocusImage2 from '../assets/images/ad.jpg';
import FocusImage3 from '../assets/images/ad2.jpg';

const advantage = [
    {
      title: 'Adolescent Girls',
      image: FocusImage1,
      points: [
        'Concept & application based learning.',
        'Interactive & engaging lectures.',
        'Integrated approach focusing on competitive & school exams.',
      ],
    },
    {
      title: 'Widows',
      image: FocusImage2,
      points: [
        'Well-researched & analyzed.',
        'Comprehensive & meticulously designed.',
        'Based on latest syllabus & exam pattern.',
      ],
    },
    {
      title: 'Destitute Women',
      image: FocusImage3,
      points: [
        'Detailed test analysis with error analysis report.',
        'Simulated tests based on latest exam pattern.',
        'Compete at national level with All India Test Series.',
      ],
    },
    // Add more advantage sections here
  ];

const MahilaVikas = () => {
  return (
    <>
    <section className="mahilavikas-section">
      <h2>Project Summary</h2>
      <div className="mahilavikas-container">
        <div className="mahilavikas-image">
          <img src={Image1} alt="mahilavikas" />
          <img src={Image2} alt="mahilavikas" />
        </div>
        <div className="mahilavikas-content">
            <p>
            Women's empowerment is the process of giving women the ability to make choices and control their own lives. It involves removing barriers that prevent women from achieving their goals and participating in society. Women's empowerment is a fundamental human right and is essential for sustainable development.
            </p>
            <p>
            Women's empowerment is key to economic and social outcomes. The benefits of projects that empower women go beyond just gender mainstreaming. More than half of bilateral finance for agriculture and rural development already mainstreams gender, but only 6 percent consider gender fundamental.
            </p>
            <p>
            If half of the smallholders benefited from development interventions focused on women's empowerment, this would significantly increase the incomes of an additional 58 million people and increase the resilience of an additional 235 million people.
            </p>
            <p>
            Our thinking behind <strong>PROJECT MAHILA VIKAS</strong> can be defined in many ways as accepting the perspective of women, trying to explore them and uplift the status of women through education, awareness and literacy and give equal status in society, better livelihood and training.
            </p>
            <div className="download-button-container">
              <a href="/MAHILA_VIKAS.pdf" download className="download-button">DOWNLOAD FOR MORE</a>
            </div>
        </div>
      </div>

      {/* <div className="download-button-container">
        <a href="/MAHILA_VIKAS.pdf" download className="download-button">DOWNLOAD FOR MORE</a>
      </div> */}

    </section>

    <section className="advantage-section">
      <h2 className="advantage-titles">OUR FOCUS AREAS AT A GLANCE</h2>
      <div className="advantage-grid">
        {advantage.map((adv, index) => (
          <div key={index} className="advantage-card">
            {/* <div className="icon">{advantage.icon}</div> */}
            <img src={adv.image} alt={adv.title} className="advantage-image" />
            <h3>{adv.title}</h3>
            <ul>
              {adv.points.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default MahilaVikas;
