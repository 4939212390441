import React, { useState } from "react";
import donatebanner from "../assets/images/Donate.jpg";

const Donate = () => {
  const [amount, setAmount] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Empowering Women & Children");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    amount:'',
		designate:'',
		name:'',
		email:'',
		phone:'',
		message:'',
		country:'',
    city:'',
		state:'',
		address:'',
		pinCode:'',
		panNumber:'',

  })

  const indianStates = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka",
    "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
    "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim",
    "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand",
    "West Bengal", "Andaman and Nicobar Islands", "Chandigarh",
    "Dadra & Nagar Haveli & Daman & Diu", "Delhi", "Jammu and Kashmir",
    "Ladakh", "Lakshadweep", "Puducherry",
  ];

  const handleSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAmountChange = (value) => {
    const sanitizedValue = value.replace(/^0+/, "");
    if (/^[0-9]*$/.test(sanitizedValue)) {
    setAmount(sanitizedValue);
    }
    if (sanitizedValue === "" || Number(sanitizedValue) >= 
    500
  ) {
      setErrorMessage("");
    }
  };

  const handleRadioChange = (value) => {
    if (value === "other"){
      setAmount("");
      setIsOtherSelected(true);
      setErrorMessage("");
    } else {
      setAmount(value);
      setIsOtherSelected(false);
      setErrorMessage("");
    }
  };

  const handleChange = (e) =>{
    const{id, value} = e.target
    setFormData(prev=>({
      ...prev,
      [id]:value
    }))
  }

  const handleDonateNowClick = () => {
    if (Number(amount) < 
    500
     || amount === "") {
      setErrorMessage("The Minimum Donation Amount Is ₹500.");
    } else {
    setShowForm(true);
    }
  };

  const handleFormSubmit = async(e) => {
    e.preventDefault();

    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const phoneRegex = /^\d{10}$/;
    const pinCodeRegex = /^\d{6}$/;
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/;

    const errors = {};
    const name = e.target[0].value;
    const email = e.target[1].value;
    const phone = e.target[2].value;
    const pinCode = e.target[7].value;
    const pan = e.target[8].value;

    if (!nameRegex.test(name)) {
      errors.name = "Invalid full name. Please enter proper name.";
    }
    if (!emailRegex.test(email)) {
      errors.email = "Invalid email address.";
    }
    if (!phoneRegex.test(phone)) {
      errors.phone = "Invalid phone number. It should be 10 digits.";
    }
    if (!pinCodeRegex.test(pinCode)) {
      errors.pinCode = "Invalid pin code. It should be 6 digits.";
    }
    if (!panRegex.test(pan)) {
      errors.pan = "Invalid PAN number.";
    }

    setFormErrors(errors);

    

    if (Object.keys(errors).length === 0) {
      const payload = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        pinCode: formData.pinCode,
        panNumber: formData.panNumber,
        amount: amount,
        country: selectedCountry,
        state: selectedState,
        city: formData.city,
        address: formData.address,
        message: formData.message,
        designate: 'something',
      };

      try {

        console.log("the request is being send");
          // Send the POST request
          const response = await fetch('https://aaradhyafoundation.org.in/api/v1/donate/create', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
          });

          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Parse the JSON response
          const data = await response.json()
          console.log(data.data.instrumentResponse.redirectInfo.url);
          
          const redUrl = data.data.instrumentResponse.redirectInfo.url
          // Redirect to the unique URL from the response
          
          // console.log(url)
          window.location.href = redUrl;

          console.log("the request end");
          
      } catch (error) {
          console.error('Error submitting form:', error);
          alert('An error occurred while submitting the form.');
      }
      



      
      
    }
  };

  return (
    <div className="donate-container">
      {/* Banner Section */}
      <div className="donation-banner">
        <img src={donatebanner} alt="Donation Banner" className="donation-banner-image" />
      </div>

      {/* Donate Message */}
      <div className="donate-message-section">
        <div className="text-content">
          <h2>DONATE FOR AN EMPOWERD FUTURE</h2>
          <p>
          We do a wide range of services to help individuals and communities in need. These services may include providing Education, Resources, and Health care to those who may not otherwise have access. One of the primary services offered by us is Education. We ensure that children and young adults have access to quality education, regardless of socioeconomic status. This can include providing scholarships, funding for school supplies, and after-school programs to help students succeed. By providing these resources, our goal is to help individuals and families become more self-sufficient and improve their overall quality of life.
          </p>
          <p>
          Another important service offered by us is Healthcare. We work to improve access to healthcare in underserved communities, providing medical services and resources to those who might not otherwise have access. This can include everything from free clinics and health screenings to mental health services and addiction treatment programs.
          </p>
          <p>
          Join our charitable mission to make a difference. Your donation, no matter the size, can help change lives and create a better, more just world. Together, we can create a ripple effect of positive change that extends far beyond our communities.
          </p>
          <p>
          Donate today and be a part of something truly transformative. Every contribution counts, and your support can help bring about the lasting solution we seek for humanity.
          </p>
          <p> THANK YOU, <br />
          <strong>AARADHYA FOUNDATION</strong></p>
        </div>

        {/* Donation Card */}
        <div className="donation-card-container">
          {!showForm ? (
            <div className="donation-card">
              <h2>Support The Cause</h2>
              <div className="amount-options">
                {[1500, 3000, 6000, 12000].map((value) => (
                  <label key={value} className={`radio-label ${
                  amount === String(value) ? "selected" : "" 
                  }`}>
                    <input
                      type="radio"
                      name="donationAmount"
                      value={value}
                      onChange={() => handleRadioChange(String(value))}
                      checked={amount === String(value)}
                    />
                    ₹{value}
                  </label>
                ))}
                <label className={`radio-label ${
                  isOtherSelected ? "selected" : "" 
                  }`}>
                  <input
                    type="radio"
                    name="donationAmount"
                    value="other"
                    onChange={() => handleRadioChange("other")}
                    checked={isOtherSelected}
                  />
                  Other
                </label>
              </div>
              <div className="quote">
              <p>YOUR DONATION WILL HELP FOR THE BETTER FUTURE </p>
              </div>
              <input
                type="text"
                placeholder="₹ Enter Amount"
                value={amount}
                onChange={(e) => handleAmountChange(e.target.value)}
                className="amount-input"
              />
                {errorMessage && <p className="error-message">{errorMessage}</p>}

                <div className="donate-designation">
                  <p>
                    Designate to{" "}
                    <select  className="custom-dropdown" value={selectedOption} onChange={handleSelect}>
                      <option value="Empowering Women & Children">Empowering Women & Children</option>
                      <option value="Health">Health</option>
                      <option value="Education">Education</option>
                      <option value="Rural Infrastructure">Rural Infrastructure </option>
                      <option value="Vocational Training">Vocational Training </option>
                      <option value="Save Environment">Save Environment </option>
                      <option value="Alternative Therapy">Alternative Therapy </option>
                      <option value="Research in Health Care">Research in Health Care</option>
                    </select>
                  </p>
                </div>

              <button className="donate-btn" onClick={handleDonateNowClick}>
                Donate Now
              </button>
            </div>
          ) : (
            <div className="donation-form-card">
              <h2>Complete Your Donation</h2>
              <form onSubmit={handleFormSubmit}
                // onSubmit={(e) => {
                //   e.preventDefault();
                //   handleFormSubmit();
                // }}
                className="donation form"
              >
                <div className="form-group">
                  <input type="text" placeholder="Enter Full Name" id="name" onChange={handleChange} required />
                  {formErrors.name && <p className="error-text">{formErrors.name}</p>}
                  <input type="email" placeholder="Email" required id="email" onChange={handleChange}/>
                  {formErrors.email && <p className="error-text">{formErrors.email}</p>}
                </div>

                <div className="form-group">
                  <input type="tel" placeholder="Phone No." required id="phone" onChange={handleChange} />
                  {formErrors.phone && <p className="error-text">{formErrors.phone}</p>}
                  <select style={{width: '48%', border: '1px solid #ccc', borderRadius:'5px', color:'grey', fontSize:'0.9rem', paddingLeft:'5px'}}
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    required
                  >
                    <option value="India">India</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="form-group">
                {selectedCountry === "India" ? (
                    <select style={{width: '48%', border: '1px solid #ccc', borderRadius:'5px', color:'grey', fontSize:'0.9rem', paddingLeft:'5px'}}
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      required
                    >
                      <option value="" disabled>
                        Select State
                      </option>
                      {indianStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                      <option value="Other">Other</option>
                    </select>
                  ) : (
                    <input
                      type="text"
                      placeholder="Enter State"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                      required
                    />
                  )}
                  <input type="text" placeholder="City" required id="city" onChange={handleChange} />
                </div>

                <div className="form-group">
                  <input type="text" placeholder="Address" required  id="address" onChange={handleChange}/>
                  <input type="text" placeholder="Pin Code" required id="pinCode" onChange={handleChange} />
                  {formErrors.pinCode && <p className="error-text">{formErrors.pinCode}</p>}
                </div>

                <div className="form-group">
                  <input type="text" placeholder="PAN No." required id="panNumber" onChange={handleChange} />
                  {formErrors.pan && <p className="error-text">{formErrors.pan}</p>}
                  <textarea placeholder="Your Message" required id="message" onChange={handleChange}></textarea>
                </div>

                <button type="submit" className="donate-btn">
                  Donate
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Donate;