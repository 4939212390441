import React from 'react';
import './PrivacyPolicy.css';

const RefundPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Refund Policy</h1>
      <p>At <strong>Aaradhya Foundation</strong>, we ensure transparency in the use of donor funds. Please review our Refund Policy below:</p>
      <h2>Donation Refunds</h2>
      <ul>
        <li><strong>Refund Requests:</strong> If you made a donation in error, you may request a refund within <strong>7 days</strong> of the donation.</li>
        <li><strong>Non-Refundable Donations:</strong> Donations made after 7 days are non-refundable.</li>
        <li><strong>Processing Refunds:</strong> Approved refunds will be credited within <strong>7-10 business days</strong>.</li>
      </ul>
      <h2>Contact for Refunds</h2>
      <p>For any refund-related inquiries, please contact us at info@aaradhyafoundation.org.in.</p>
    </div>
  );
};

export default RefundPolicy;
