import React, { useState, useEffect, useCallback } from 'react';

const HeroSection = () => {
  const slides = [
    { image: require('../assets/images/Website_Banner_1.jpg'), title: 'School/College is where ‘time flies’ takes on a whole new meaning', text: '' },
    { image: require('../assets/images/Website_Banner_2.jpg'), title: 'Change is the end result of all true learning', text: '' },
    { image: require('../assets/images/Website_Banner_3.jpg'), title: 'Energize.....‘LIVELIHOOD’', text: '' },
    { image: require('../assets/images/Website_Banner_4.jpg'), title: 'Women empowerment is the key to unlocking the potential of a nation', text: '' },
    { image: require('../assets/images/Website_Banner_5.jpg'), title: 'A healthy outside starts from the inside', text: '' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Using useCallback ensures that the function reference remains the same
  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  }, [slides.length]);

  // const prevSlide = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide(); // Use the callback version to avoid issues
    }, 3000); // 5-second interval for sliding

    return () => clearInterval(interval); // Clean up the interval when component unmounts
  }, [nextSlide]); // Adding `nextSlide` as a dependency ensures it works correctly

  return (
    <div className="hero-section">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`hero-slide ${index === currentIndex ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
          <div className="hero-content">
            <h1>{slide.title}</h1>
            <p>{slide.text}</p>
            {/* <button className="cta-btn">JOIN US</button> */}
          </div>
        </div>
      ))}

      {/* <div className="carousel-nav">
        <button onClick={prevSlide}>&lt;</button>
        <button onClick={nextSlide}>&gt;</button>
      </div> */}
    </div>
  );
};

export default HeroSection;
