// AnnouncementsEvents.js
import React from 'react';

const announcements =[
  { id: 1, title: 'Admission Open for New Batch 2024', link: '#', isNew: true },
  { id: 2, title: '2024 - Get Early Bird Offer', link: '#', isNew: true },
  { id: 3, title: 'Abacus Results Announced', link: '#', isNew: true }
];

const events = [
  { id: 1, title: 'Vedic Maths Batch Coming Next Week', link: '#', isNew: true },
  { id: 2, title: 'Genernal Knowledge Exam Registration is Open', link: '#', isNew: true },
  { id: 3, title: 'Olympaid and Scholarship Batched Announced Soon', link: '#', isNew: true }
];

const Item = ({ item }) => (
  <div className="item">
    <span className="item-title">{item.title}</span>
    <a className="item-link" href={item.link}>{item.isNew ? 'New' : 'View'}</a>
  </div>
);

const Section = ({ title, items }) => (
  <div className="section">
    <h2 className="section-title">{title}</h2>
    {items.map(item => <Item key={item.id} item={item} />)}
  </div>
);

const AnnouncementsEvents = () => (
  <div className="container" id="announcementsevents">
    <Section title="ANNOUNCEMENT" items={announcements} />
    <Section title="UPDATES" items={events} />
  </div>
);

export default AnnouncementsEvents;
